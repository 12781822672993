import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import PageBanner from '../components/PageBanner'

const Privacy = (props) => (
    <Layout>
        <Helmet>
            <title>Speisekarte - Gül Kebap Und Pizza Haus Lambrecht(Pfalz) - Essen zum Mitnehmen - Lambrecht (Pfalz) - Online bestellen</title>
            <meta name="description" content="Gül Kebap Und Pizza Haus Lambrecht(Pfalz), Datenschutz, Online Essen Bestellen" />
        </Helmet>
        <PageBanner pageTitle="Datenschutz" />

        <div id="main" className="alt-grey">
        <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h3>Gül Kebap und Pizza Haus Lambrecht(Pfalz)</h3>
                            </header>

                            <h4>Datenschutzbestimmungen</h4>
                            <h4>Schutz persönlicher Daten</h4>
                            <p>
                                
                                Wir nehmen den Schutz Ihrer persönlichen Daten ernst und möchten, dass Sie sich auf unserer
                                Seite keine Sorgen um Ihre Privatsphäre machen müssen.
                                Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                                Datenschutzvorschriften, der Datenschutzgrundverordnung (DSGVO) sowie dieser
                                Datenschutzerklärung. Bitte nehmen Sie sich ein wenig Zeit dafür, diese Erklärung zum
                                Datenschutz durchzulesen.
                            </p>
                            <h4>Verantwortliche Stelle</h4>
                            <p>
                                Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen
                                Daten ist:
                                Mustafa Gül
                                Hauptstraße 95, 
                                67466 Lambrecht (Pfalz)
                            </p>
                            <h4>Ihre Rechte</h4>
                            <p>
                                Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
                                Im Rahmen unseres online Lieferservice erfassen wir ggf. Daten, wie beispielsweise Ihren Namen
                                oder Ihre private oder geschäftliche Adresse bzw. Telefonnummer. Nach dem Bestellvorgang
                                werden diese Daten in einer Datenbank gespeichert. Diese Daten dienen der Abwicklung des
                                Rechnungsverfahrens und außerdem der Pizzeria Appetito als Marketinginstrument zur besseren
                                Abstimmung des Angebotes auf Kundenwünsche. Diese Daten werden nur mit Ihrer Zustimmung
                                erhoben und nicht an Dritte zu Werbezwecken weitergegeben.
                            </p>
                            <h4>Auskunftsrecht</h4>
                            <p>
                                Sie haben das Recht auf kostenlose Auskunft über die Verarbeitung Ihrer personenbezogenen
                                Daten. Sie haben außerdem das Recht die Berichtigung, die Löschung oder eine eingeschränkte
                                Verarbeitung Ihrer Daten zu verlangen. Für eine schriftliche oder elektronische Auskunftserteilung
                                ist ein Identitätsnachweis durch Kopie eines amtlichen Ausweises mit Unterschrift erforderlich.
                            </p>
                            <h4>Widerspruchsrecht</h4>
                            <p>
                                Der Verwendung der Adressdaten, die im Rahmen einer Online-Bestellung erhoben wurden, kann
                                in einer Email an appetito.garbsen@gmail.com widersprochen werden.
                                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per
                                E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                                Dritte ist nicht möglich.
                                Wie persönliche Daten bei einem Online-Bestellvorgang durch den Anbieter „Gloria-Food“
                                behandelt werden, wird im Abschnitt „Cookies“ erklärt.
                            </p>
                            <h4>Cookies</h4>
                            <p>
                                Ein Cookie ist ein kurzes Text-Fragment, das von einer von Ihnen besuchten Website an Ihren
                                Browser gesendet wird. Es speichert Informationen zu Ihrem letzten Besuch, wie Ihre bevorzugte
                                Sprache oder andere Einstellungen. So finden Sie sich auf der Website schneller zurecht und
                                nutzen sie effektiver, wenn Sie sie das nächste Mal aufrufen. Cookies spielen außerdem eine
                                wichtige Rolle bei einer Online-Bestellung. Eine Benutzersitzung mit Cookies ermöglicht dabei das
                                Füllen des Einkaufskorbes und die Abwicklung des Bestellvorganges.
                            </p>
                            <h4>Google Analytics</h4>
                            <p>
                                Diese Website benutzt Google Analytics, ein Analysetool von Google, das für Website- und AppInhaber die Interaktion Ihrer Nutzer mit den angebotenen Ressourcen transparent macht. Google
                                Analytics verwendet möglicherweise bestimmte Cookies, um Daten zu erheben und eine WebsiteNutzungsstatistik weiterzugeben, ohne dabei jedoch Informationen zu erfassen, die einzelne
                                Nutzer für Google persönlich identifizierbar machen. Das wichtigste Google Analytics-Cookie
                                heißt “__ga".
                                Neben der Erstellung der Nutzungsstatistik für Websites kann Google Analytics auch zusammen
                                mit einigen der oben beschriebenen Werbecookies dazu beitragen, dass in Google-Produkten wie
                                etwa der Google-Suche sowie im gesamten Web relevantere Werbeanzeigen eingeblendet werden
                                und um Interaktionen mit der von uns gezeigten Werbung zu messen.
                                Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse über Google Analytics
                                den Zusatz "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse des
                                Internetanschlusses der betroffenen Person von Google gekürzt und anonymisiert, wenn der
                                Zugriff auf meine Internetseiten aus einem Mitgliedstaat der Europäischen Union oder aus einem
                                anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum erfolgt.
                                Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der
                                Ort, von welchem ein Zugriff ausging und die Häufigkeit der Besuche unserer Internetseite durch
                                die betroffene Person, gespeichert. Bei jedem Besuch meiner Internetseite werden diese
                                personenbezogenen Daten, einschließlich der IP-Adresse des von der betroffenen Person
                                genutzten Internetanschlusses, an Google in den Vereinigten Staaten von Amerika übertragen.
                                Diese personenbezogenen Daten werden durch Google in den Vereinigten Staaten von Amerika
                                gespeichert. Google gibt diese über das technische Verfahren erhobenen personenbezogenen
                                Daten unter Umständen an Dritte weiter.
                                Die Informationen werden in die Vereinigten Staaten transferiert und dort auf Servern gespeichert.
                                Google erklärt, dass sie sich an mehrere Übereinkommen zur Selbstregulierung halten, unter
                                anderem auch an das EU-US Privacy Shield Framework. Weitere Informationen zum EU-USDatenschutzschild sowie die Zertifizierung von Google finden Sie auf der Privacy Shield-Website.
                                Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
                                https://www.google.de/intl/de/policies/privacy/ und unter http://www.google.com/analytics/terms/
                                de.html abgerufen werden. Google Analytics wird unter diesem Link https://www.google.com/intl/
                                de_de/analytics/ genauer erläutert. Nähere Informationen zu Nutzungsbedingungen und
                                Datenschutz finden Sie unter http://www.google.com/intl/de/analytics/privacyoverview.html.
                                Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um den Code
                                „ga._anonymizeIp();“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IPMasking) zu gewährleisten.
                            </p>
                            <h4>Google Maps</h4>
                            <p>
                                Diese Website benutzt Google Maps zur Darstellung eines Lageplanes. Google Maps wird von
                                Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA betrieben. Durch die
                                Nutzung dieser Website erklären Sie sich mit der Erfassung, Bearbeitung sowie der Nutzung der
                                automatisch erhobenen sowie der von Ihnen eingegeben Daten durch Google, einer seiner
                                Vertreter, oder Drittanbieter einverstanden.
                                Weitere Informationen unter https://www.google.com/intl/de_US/help/terms_maps.html und
                                https://policies.google.com/privacy?hl=de
                            </p>
                            <h4> Online Bestellsystem</h4>
                            <p>
                                • Kategorie 1 - unbedingt notwendige Cookies. Diese Cookies sind notwendig, damit Sie sich
                                in der Webanwendung des Dienstleisters bewegen und seine Funktionen nutzen können, z. B.
                                Zugriff auf sichere Bereiche. Ohne diese Cookies können keine Dienste wie Warenkörbe oder
                                E-Rechnung angeboten werden.
                                • Kategorie 2 - Leistungs-Cookies. Diese Cookies sammeln Informationen darüber, wie Sie die
                                Webanwendung des Dienstleisters verwenden - z. B. welche Seiten Sie am häufigsten
                                aufrufen und ob sie Fehlermeldungen von Webseiten und / oder der Webanwendung erhalten.
                                Diese Cookies sammeln keine Informationen, die einen Besucher identifizieren. Alle
                                Informationen, die diese Cookies sammeln, sind aggregiert und daher anonym. Sie werden nur
                                verwendet, um die Funktionsweise der Website des Dienstleisters und / oder der Anwendung
                                zu verbessern. Als Drittanbieter-Cookies in dieser Kategorie verwenden wir Google Analytics,
                                einen Dienst, der Website-Traffic-Daten an Google-Server in den USA übermittelt. Die von
                                Google Analytics bereitgestellten Berichte helfen uns dabei, den Webseitenverkehr und die
                                Webseitennutzung zu verstehen. Google Analytics identifiziert keine individuellen Benutzer
                                oder weist Ihre IP-Adresse anderen Daten von Google zu.
                                • Kategorie 3 - Funktionalität Cookies. Diese Cookies ermöglichen es der Webanwendung des
                                Dienstleisters, die von Ihnen während des Surfens und / oder der Verwendung der Anwendung
                                getroffenen Entscheidungen zu speichern (z. B. Ihr Benutzername, Ihre Sprache oder die
                                Region, in der Sie sich befinden) und erweiterte, persönlichere Funktionen bereitzustellen.
                                Diese Cookies können auch verwendet werden, um sich an Änderungen zu erinnern, die Sie
                                an Textgröße, Sprache und anderen Teilen von Webseiten vorgenommen haben, die Sie
                                anpassen können. Die Informationen, die diese Cookies sammeln, werden Sie nicht persönlich
                                identifizieren, und sie können Ihre Surfaktivität auf Websites außerhalb des Dienstleisters nicht
                                verfolgen. Für eine zuverlässige Überprüfung Ihres Bestellstatus, auf dem Bildschirm der
                                Anwendung, in Echtzeit und auch für eine einfache Nachbestellung können Ihre Daten auf
                                Ihrem Gerät gespeichert werden und lokal ein Cookie bereitstellen.
                                • Kategorie 4 - Targeting-Cookies oder Werbe-Cookies. Bei diesen Cookies handelt es sich in
                                der Regel um Cookies von Drittanbietern. Wenn ein Nutzer jedoch die Website des
                                Werbenetzwerks besucht, ist es technisch möglich, dass diese auch vom Erstanbieter
                                kommen. Es sind immer persistente, aber zeitlich begrenzte Cookies. Diese Cookies können 
                                mit Diensten verbunden sein, die von Dritten bereitgestellt werden, dies ist jedoch nicht immer
                                der Fall. Diese Cookies enthalten einen eindeutigen Schlüssel, der in der Lage ist, die
                                Surfgewohnheiten einzelner Benutzer zu unterscheiden, oder die anhand von Informationen,
                                die an anderer Stelle gespeichert sind, in eine Reihe von Surfgewohnheiten oder Präferenzen
                                umgewandelt werden können. Im Allgemeinen sollte die Datenschutzerklärung angeben, ob
                                der Cookie als Teil eines Werbenetzwerks verwendet wird. Cookies können auch dazu
                                verwendet werden, die Anzahl der Besuche eines Nutzers auf einer Website zu begrenzen und
                                die Effektivität einer bestimmten Kampagne zu messen.
                            </p>
                            <h4>Soziale Medien</h4>
                            <p>
                                Unsere Website verfügt über Schaltflächen, um die Websites in sozialen Medien wie z. B.
                                Facebook, Instagram, und Google+ promoten. Diese Schaltflächen funktionieren über kurze
                                Codes, die von Facebook, Instagram, und Google+ selbst stammen. Mittels dieser Codes werden
                                Cookies platziert. Lesen Sie die Datenschutzerklärung von Facebook, Instagram, and Google+
                                (die regelmäßig geändert werden können), um zu erfahren, wie sie mit Ihren (personenbezogenen)
                                Daten umgehen, die sie über diese Cookies verarbeiten.
                                Die von ihnen erfassten Informationen werden möglichst weitgehend anonymisiert. Die
                                Informationen werden von Facebook, Instagram und in die Vereinigten Staaten
                                transferiert und dort auf Servern gespeichert. Diese vier Dienstleister erklären, dass sie sich an
                                mehrere Übereinkommen zur Selbstregulierung halten, unter anderem auch an das EU-US Privacy
                                Shield Framework. Weitere Informationen zum EU-US-Datenschutzschild sowie die Zertifizierung
                                der betreffenden Dienstleister finden Sie auf der Privacy Shield.
                            </p>
                            <h4>Dauer der Speicherung</h4>
                            <p>
                                Informationen zu der Speicherdauer einzelner Cookies finden Sie in den oben verlinkten
                                Datenschutzerklärungen von Google, Facebook und Instagram.
                                Wir weisen darauf hin, dass Cookies lokal auf dem Rechner des Nutzers gespeichert werden.
                                Daher haben Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine
                                Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies
                                deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden.
                                Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Webseite deaktiviert, können
                                möglicherweise nicht mehr alle Funktionen der Webseite vollumfänglich genutzt werden. 
                            </p>
       


                        </div>
                    </section>
        </div>

    </Layout>
)

export default Privacy