import React from 'react'
import Order from './Order';

const PageBanner = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>{props.pageTitle}</h1>
            </header>
            <div className="content">
                <h2>Gül Kebap und Pizza Haus Lambrecht(Pfalz)</h2>
            </div>
            <Order  fixed/>
            
        </div>
    </section>
)

export default PageBanner
